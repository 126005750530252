<template>
  <div class="writer-box writer-bg">
    <HeadNavWriter ref="headeNvWriterRef"/>

    <div class="writer-box-content">

      <!-- steps -->
      <StepsWriter :active="1"/>

      <!-- elements -->
      <div class="hidden-xs-only" style="font-weight: 600; font-size: 20px; color: #000000; line-height: 24px;margin: 1rem 0;">
        故事大纲
      </div>
      <div class="writer-box-content-editor">
        <el-input @change="changeInput" v-if="!isSkeleton" id="msgbox" type="textarea" v-model="storyOutlineFull" :disabled="!storyOutlineFull"></el-input>
        <el-skeleton v-else :rows="8" animated />
      </div>
    </div>

    <!-- footer -->
    <div class="writer-box-footer" :class="{'button-flex-between': story.articleGenType == 'GEN_BY_IDEA'}">
      <span v-show="story.articleGenType == 'GEN_BY_IDEA' && storyOutlineFulls.length==0"></span>
      <span v-show="story.articleGenType == 'GEN_BY_IDEA' && storyOutlineFulls.length>0">
        <!-- <el-checkbox style="margin-right: .5rem;" v-model="checked4ElementVersion" @change="changeElementVersion"></el-checkbox> -->
        <el-select class="custom-select"
            v-model="story.storyOutlineFullId"
            filterable
            size="mini"
            :disabled="isLoading"
            placeholder="版本">
          <el-option
            v-for="(itemVersion, index) in storyOutlineFulls"
            @click.native.stop="contentChange(itemVersion)"
            :key="itemVersion.id"
            :label="'版本 ' + itemVersion.version"
            :value="itemVersion.id"
          />
        </el-select>
      </span>
      <span class="button-group">
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" round @click="genStoryOutlineFull()" :loading="isLoading" :disabled="isLoading" v-if="story.articleGenType == 'GEN_BY_IDEA' && storyOutlineFulls.length==0">生成大纲</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="default" round icon="el-icon-refresh" @click="handleSave(1)" :loading="isLoading" :disabled="isLoading" v-if="story.articleGenType == 'GEN_BY_IDEA' && storyOutlineFulls.length>0">重新生成</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" @click="handleSave(2)" :disabled="isLoading" v-if="story.articleGenType == 'GEN_BY_IDEA' && storyOutlineFulls.length>0">下一步</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" @click="handleSave(0)" :disabled="isLoading" v-if="story.articleGenType == 'GEN_BY_OUTLINE' && storyOutlineFulls.length>0">下一步</el-button>
      </span>
    </div>

  </div>
</template>
<script>
import StepsWriter from '@/components/StepsWriter'
import HeadNavWriter from '@/components/HeadNavWriter'
import LyRichEditor from '@/components/RichEditor'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import * as StoryElementApi from '@/api/storyelement'
import * as StoryOutlineApi from '@/api/storyoutline'
import * as StoryOutlineFullApi from '@/api/storyoutlinefull'
import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source"
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'Article',
  components: {
    LyRichEditor, HeadNavWriter, StepsWriter
  },
  data() {
    return {
      checked4ElementVersion: false,
      fromType: 'novel',
      enums: [],
      isRequesting: false,
      isLoading: false,
      isSkeleton: false,
    	targetPermit: 'article',
      tableHeight: 100,
      // storyOutline: null,
      storyOutlinesPrue: [],
      storyOutlineFull: null,
      // storyOutlineFulls: [{id: '0', content: '', version: 1}],
      storyOutlineFulls: [],
      storyOutlineTemplate: `01
xxx

02
xxx

03
xxx

04
xxx

05
xxx

`
    }
  },
  computed: {
  	...mapState(['user', 'story', 'dialogShowCostomContactFlag'])
  },
  mounted() {
    this.$nextTick(() => {

    })
    this.handlePageStoryOutlineFull()
    this.handleEnum()
  },
  methods: {
    ...mapMutations(['setStory', 'setDialogShowCostomContactFlag']),
    removeHtmlTagsAndReplaceBrWithNewline(htmlString) {
      // console.log('html', htmlString)
      // 将 <br> 替换为换行符
      if(!htmlString){
        htmlString = ''
      }
      let newlineString = htmlString.replace(/(?=\S)<br\s*\/?>/gi, '');
      const regexP = /<p>(.*?)<\/p>/g;
      newlineString = newlineString.replace(regexP, (match, content) => {
        return `${content}\n`; // 保留内容并在其后追加换行符
      });
      // 去除 HTML 标签
      let noHtmlString = newlineString.replace(/<[^>]*>?/gm, '');
      noHtmlString += "\n 9527"
      console.log('remove html tag', noHtmlString)
      return noHtmlString;
    },
    isContentFormatted() {
      let text = this.removeHtmlTagsAndReplaceBrWithNewline(this.storyOutlineFull)
       const regex = /^(?:\s*(\d+)\s*(?:\n(?!\s*\d+\s*$).*)*)(?=(\n\s*\d+\s*$)|\Z)/gm;

       let matches;
       const outlineNumber = [];
       this.storyOutlinesPrue = []
       // 使用循环来处理所有匹配的项
       while ((matches = regex.exec(text)) !== null) {
         const number = matches[1];
         // matches[0] 是整个匹配的字符串，包括数字和文本
         const groupWithNumber = matches[0];

         // 提取文本内容，去除数字行
         // const texts = groupWithNumber.split('\n').slice(1); // 从第二行开始，因为第一行是数字
         const texts = groupWithNumber.replace(number, '').trim(); // 从第二行开始，因为第一行是数字
          outlineNumber.push(parseInt(number, 10))
          // 将数字和文本内容添加到结果数组
          this.storyOutlinesPrue.push(texts);
       }
       let flag = true
       for(let i=0; i<outlineNumber.length; i++){
         if(outlineNumber[i] != i + 1){
           this.$message.error("抱歉，章节序号有误，章节序号必须为纯数字，例如01，并且每个章节数字递增。当前序号：" + outlineNumber.join('->'))
           flag = false;
           break;
         }
       }
       console.log('outlineNumber -------------- ' + outlineNumber);
       console.log('storyOutlinesPrue -------------- ' + this.storyOutlinesPrue);
       return flag;
    },
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    contentChange(item){
      console.log('contentChange', item.content);
      this.storyOutlineFull = item.content
      this.story.storyOutline = item.content
      this.story.storyOutlineFullId = item.id
      this.setStory(this.story)
      this.handleUpdateStory(item)
    },
    changeInput(event){
      console.log('changeInput', event);
      this.handleUpdateOutlineFull()
    },
    handleUpdateStory(item) {
      ArticleApi.update({id: this.story.id,
          storyOutlineFullId: item.id,
          storyOutline: item.content}
      ).then(res => {

      });
    },
    handleUpdateOutlineFull() {
      if(this.isLoading){// 正在生成内容
        return false;
      }
      let isPass = this.isContentFormatted()
      console.log('handleUpdateOutlineFull isPass', isPass);
      if(!isPass){
        return false;
      }
      // console.log('handleSave storyOutlineFull', this.storyOutlineFull, this.storyOutlineTemplate, this.storyOutlineFull == this.storyOutlineTemplate);
      if (!this.storyOutlineFull || !this.storyOutlineFull.trim() || this.storyOutlineFull == this.storyOutlineTemplate) {
          // this.$message.error('请输入故事大纲');
          console.log('handleUpdateOutlineFull 大纲丢失 storyOutlineFull');
          return false;
      }
      this.isRequesting = true
      // 用户修改大纲，自动保存（生成过正文；未生成过
      console.log('storyOutlinesPrue update -------------- ' + this.storyOutlinesPrue);
      StoryOutlineFullApi.create({storyId: this.story.id,
        content: this.storyOutlineFull, storyOutlineFullId: this.story.storyOutlineFullId, fromType: 'outline_auto_save',
        storyOutlines: this.storyOutlinesPrue}).then(res => {
        this.story.storyOutlineFull = this.storyOutlineFull
        // this.storyOutlineFulls[result.records.length-1].content = this.storyOutlineFull
        this.story.stepNumber = 3
        this.setStory(this.story)
        this.isRequesting = false
      })
    },
    handlePageStoryOutlineFull(flag){
      StoryOutlineFullApi.page({storyId: this.story.id, size: 100}).then(res => {
        let result = res.data.data;
        if(res.data.code == 0){
          if(result.records.length > 0){
            this.storyOutlineFulls = result.records;
            let currentOutlineFull = this.storyOutlineFulls.filter(i=>{return i.id==this.story.storyOutlineFullId})[0];
            if(currentOutlineFull){
              this.storyOutlineFull = currentOutlineFull.content
              this.story.storyOutlineFullId = currentOutlineFull.id
            }else{
              this.story.storyOutlineFullId = this.storyOutlineFulls[result.records.length-1].id
              this.storyOutlineFull = this.storyOutlineFulls[result.records.length-1].content
            }
          }else{
            // this.story.storyOutlineFullId = '0'
            // this.storyOutlineFulls = [{id: '0', content: '', version: 1}];
            this.storyOutlineFulls = [];
          }

          this.story.storyOutlineFull = this.storyOutlineFull
          this.setStory(this.story)

          if ((!this.storyOutlineFull || !this.storyOutlineFull.trim())
           && (this.story.articleGenType == 'GEN_BY_OUTLINE')) {
            this.storyOutlineFull = this.storyOutlineTemplate
          }
          console.log('handlePageStoryOutlineFull', this.story, result.records);
          if(result.records.length == 0 && this.story.articleGenType == 'GEN_BY_IDEA'){// 从创意开始，自动生成要素

            if(this.user.desktopFlag){
              this.genStoryOutlineFull()
            }else{

            }
          }
          if(flag && flag == 3 && this.story.articleGenType == 'GEN_BY_IDEA'){
            this.contentChange(this.storyOutlineFulls[this.storyOutlineFulls.length-1])
          }
        }
      })
    },
    handleEnum() {
      EnumApi.list().then(res => {
        let result = res.data.data;
        this.enums = result
      })
    },
    handleSave(flag) {
      let isPass = this.isContentFormatted()
      console.log('handleSave isPass', isPass);
      if(!isPass){
        return false;
      }
      console.log('handleSave storyOutlineFull', this.storyOutlineFull, this.storyOutlineTemplate, this.storyOutlineFull == this.storyOutlineTemplate);
      if (!this.storyOutlineFull || !this.storyOutlineFull.trim() || this.storyOutlineFull == this.storyOutlineTemplate) {
          this.$message.error('请输入故事大纲');
          return false;
      }
      if(flag == 0){//by outline 下一步 只有一个版本
        // 避免重复
        setTimeout(() => {this.$router.push('/workspace/genbyoutline3');}, 1000);

      }else if(flag == 1){// by idea : 重新生成
        this.genStoryOutlineFull()
      }else if(flag == 2){// by idea : 下一步 (以当前选择的版本生文)
        if(this.story.stepNumber < 3){
          this.story.stepNumber = 3
          this.setStory(this.story)
        }
        setTimeout(()=>{
          this.$router.push('/workspace/genbyoutline3');
        },300)
        if(this.isRequesting) {
          this.openFullScreen()
        }else{
          this.goNextPage();
        }
      }else if(flag == 3){//by idea AI生成完之后保存版本
        StoryOutlineFullApi.create({storyId: this.story.id, content: this.storyOutlineFull,
         storyOutlines: this.storyOutlinesPrue}).then(res => {
          this.story.storyOutlineFull = this.storyOutlineFull
          console.log('genbyoutline2 flag 3')
          this.setStory(this.story)
          this.handlePageStoryOutlineFull(3)
        })
      }
    },
    handleBlur() {
      // this.handleSave(0);
    },

    openFullScreen() {
      const loading = this.$loading({
        lock: true,
        customClass: 'custom-loading',
        text: '正在保存...',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const requestingInterval = setInterval(() => {
        console.log('this.isRequesting', this.isRequesting);
        if(this.isRequesting){

        }else{
          loading.close();
          if(requestingInterval){
            clearInterval(requestingInterval)
          }
          this.goNextPage()
        }
      }, 2000);
    },
    goNextPage(){
      this.$router.push('/workspace/genbyoutline3')
    },
    scrollToBottom() {
      const msgbox = document.getElementById("msgbox")
      msgbox.scrollTop = msgbox.scrollHeight;
    },
    genStoryOutlineFull(callback) {
        console.log('story::outlinefull', this.storyOutlineFull);
        let that = this
        let cb = callback || function(){
          that.isLoading = false;
          that.isSkeleton = false;
        };
        this.isLoading = true
        this.isSkeleton = true
        if(this.storyOutlineFull){
          // 重新生成时，清空旧数据
          this.clearElementContent()
        }
        // sse start
        let url = process.env.VUE_APP_BASE_API_URL+"auth-service/im_messages/stream_workflow/1805227802388316162"
        let msg = '';
        let params = Object.assign({}, this.story)
        params.articleId = this.story.id
        params.platformCategory = 'WRITER_WEB'
        fetchEventSource(url,
            {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.lyToken,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(params),
                onmessage: (event) => {
                    let single = event.data.replace(/^"|"$/g, '').replace(/data:/g, '')
                    if (single.startsWith('ERROR1')) {
                        console.error('Received ERROR:', single);
                        this.$message.error(single)
                        cb('error1')
                    }else if (single.startsWith('ERROR2')) {
                        console.error('Received ERROR:', single);
                        // this.$message.error(single)
                        this.setDialogShowCostomContactFlag(true)
                        cb('error2')
                    }else if (single.startsWith('ERROR3')) {
                        console.error('Received ERROR:', single);
                        this.$message.error(single.replace('ERROR3:', ''))
                        cb('error3')
                    }else if (single.startsWith('ERROR4')) {
                      console.error('Received ERROR:', single);
                      this.$message.error(single.replace('ERROR4:', ''))
                      cb('error4')
                    }else if (single.startsWith('ERROR:LLM1')) {
                        this.$message.error(single.replace('ERROR:LLM1:', ''))
                        cb('ERROR:LLM1')
                        throw new Error(single)
                    }else if (single.startsWith('processInstanceId')) {
                        console.warn('Received processInstanceId:', single);
                        localStorage.setItem("processInstanceId", single.split(':')[1])
                    }else if (single.startsWith('AGENT_END')) {
                        console.warn('AGENT_END:', single);
                        this.storyOutline = msg
                        this.story.storyOutline = msg
                        this.setStory(this.story)
                        this.$refs.headeNvWriterRef.syncUser()
                        // 保存版本
                        this.handleSave(3)
                        cb('AGENT_END')
                        throw new Error('AGENT_END')
                    }else if (single.startsWith('AGENT_END_HUMAN')) {
                        console.warn('AGENT_END_HUMAN:', single);
                        cb('AGENT_END_HUMAN')
                        throw new Error('AGENT_END_HUMAN')
                    }else if (single.startsWith('queueName')) {
                        console.warn('queueName:', single);
                        localStorage.setItem("queueName", single.split(':')[1])
                    }else {
                      // console.info("onmessage", event);
                      if(single != ''){
                        this.isSkeleton = false
                        msg += single;
                        msg = msg?msg.replace(/\“/g, '“').replace(/\”/g, '”').replace(/\\"/g, '"').replace(/\\n/g, '\n'):"";
                        this.storyOutlineFull = msg
                        this.$nextTick(() => {
                          this.scrollToBottom();
                        });
                      }

                    }
                },
                onerror: (error) => {
                    throw error;
                },
                onopen: (response) => {
                    console.info("onopen", response);
                },
                onclose: (response) => {
                    console.info("onclose", response);
                },
              openWhenHidden: true
            }
        );
    },
    clearElementContent() {
      this.storyOutlineFull = ''
    }
  }
}
</script>

<style scoped lang="scss">

</style>
